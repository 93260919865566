<template>
  <div
    class="funding-wallet bg-red"
    @click="emitClose"
  >
    <div
      class="funding-wallet-sub"
      @click.stop
    >
      <div
        v-if="(showChoice && showChoix)"
        class="shadow rounded p-6  funding-dialog"
      >
        <h3 class="text-2xl font-bold">
          {{ $t('fundingWallet') }}
        </h3>
        <p class="mt-2 p-3 text-sm funding-wallet-red rounded leading-normal">
          {{ $t('fundingWalletRed') }}
        </p>
        <hr class="divider">
        <div class="choose-list shadow rounded">
          <h3 class="text-xl p-4 font-medium">
            {{ $t('chooseFundMethods') }}
          </h3>
          <div class="flex p-4 border-t font-light">
            <h3
              class="flex-one w-1/8 font-light cursor-pointer"
              @click="choosedBank"
            >
              <svg-icon
                v-if="choosed === 'visa'"
                name="ic-radio-choosed"
                class="h-4"
                original
              />
              <svg-icon
                v-if="choosed === 'rema'"
                name="ic-radio"
                class="h-4"
                original
              />
            </h3>
            <h3
              class="flex-one w-full font-light ml-2 cursor-pointer"
              @click="choosedBank"
            >
              {{ $t('bankCard') }}
            </h3>
            <h3 class="flex-one w-1/5 font-light">
              <svg-icon
                name="ic-visa"
                class=" h-4"
                original
              />
            </h3>
            <h3 class="flex-one w-1/5 text-right font-light">
              <svg-icon
                name="ic-mastercard"
                class=" h-4"
                original
              />
            </h3>
          </div>
          <div class="flex p-4 border-t font-light">
            <h3
              class="flex-one w-1/8 font-light cursor-pointer"
              @click="choosedRema"
            >
              <svg-icon
                v-if="choosed === 'rema'"
                name="ic-radio-choosed"
                class="h-4"
                original
              />
              <svg-icon
                v-if="choosed === 'visa'"
                name="ic-radio"
                class="h-4"
                original
              />
            </h3>
            <h3
              class="flex-one w-full text-lg font-light ml-2 cursor-pointer"
              @click="choosedRema"
            >
              {{ $t('remaCode') }}  <span>
                <svg-icon
                  slot="reference"
                  v-tippy
                  :content="$t('fundingWalletTooltip') + $t('fundingWalletTooltipTwo') + $t('fundingWalletThree')"
                  name="ic-info"
                  class="h-4 icon-info"
                  original
                />
              </span>
              <h4 class="mt-3 font-normal text-base cursor-pointer contact-us">
                <router-link to="/collaborators">
                  {{ $t('contactUs') }}
                </router-link>
              </h4>
            </h3>
            <h3 class="flex-one w-1/5 font-light text-white">
              b
            </h3>
            <h3 class="flex-one w-1/5 text-right font-light">
              <svg-icon
                name="ic-more-rema"
                class=" h-4"
                original
              />
            </h3>
          </div>
        </div>
        <div class="flex justify-end  mt-5">
          <div
            class="flex-one flex justify-between w-1/2 p-3 pt-4 cursor-pointer font-bold rounded border-none action-button-real bg-green text-white text-center"
            @click="goNextChoose"
          >
            <h3 class="flex-two w-full uppercase">
              {{ $t('next') }}
            </h3>
            <h3 class="flex-two w-1/5 text-center">
              <svg-icon
                name="ic-next"
                class="w-3 h3"
                original
              />
            </h3>
          </div>
        </div>
      </div>
      <!--Choisir Carte bancaire-->
      <div
        v-else-if="!showChoice"
        class="shadow rounded p-6 funding-dialog"
      >
        <h3 class="text-2xl font-bold pb-4">
          {{ $t('fundingWallet') }}
        </h3>
        <div class="flex p-4 border-t border-b font-light">
          <h3
            class="flex-one w-1/8 font-light cursor-pointer"
            @click="choosedBank"
          >
            <svg-icon
              v-if="choosed === 'visa'"
              name="ic-radio-choosed"
              class="h-4"
              original
            />
            <svg-icon
              v-if="choosed === 'rema'"
              name="ic-radio"
              class="h-4"
              original
            />
          </h3>
          <h3 class="flex-one w-full font-light font-bold ml-2">
            {{ $t('bankCard') }}
          </h3>
          <h3 class="flex-one w-1/5 font-light">
            <svg-icon
              name="ic-visa"
              class=" h-4"
              original
            />
          </h3>
          <h3 class="flex-one w-1/5 text-right font-light">
            <svg-icon
              name="ic-mastercard"
              class=" h-4"
              original
            />
          </h3>
        </div>
        <div class="flex flex-wrap w-full mt-5">
          <div
            class="checkbox-container"
            @click="haveAccount"
          >
            <div
              v-if="iHavePayDunyaAccount"
              class="checkbox-color"
            />
          </div>
          <div class="ml-4">
            J'ai un compte PayDunya
          </div>
        </div>
        <div
          v-if="!isValidation"
          class="mt-6 text-lg"
        >
          {{ $t('chooseVisaAmount') }}
        </div>
        <div
          v-if="isValidation"
          class="mt-6 text-lg"
        >
          {{ $t('isForValidation') }}
        </div>
        <div class="flex flex-wrap items-stretch w-full mt-2">
          <div class="flex-1">
            <div class="flex flex-wrap items-stretch rounded border w-full">
              <input
                v-if="!isValidation"
                v-model="cardAmount"
                type="text"
                class="flex-shrink flex-grow flex-auto uppercase leading-normal w-px h-10 px-4"
                placeholder="1600 FCFA"
                @keypress="isNumberOnly"
              >
              <input
                v-if="isValidation"
                v-model="payDunyyaValidationCode"
                type="text"
                class="flex-shrink flex-grow flex-auto uppercase leading-normal w-px h-10 px-4"
                placeholder="234445502"
                @keypress="isNumberOnly"
              >
            </div>
          </div>
        </div>
        <div
          v-if="iHavePayDunyaAccount && !isValidation"
          class="mt-6 text-lg"
        >
          {{ $t('enterEmail') }}
        </div>
        <div
          v-if="iHavePayDunyaAccount && !isValidation"
          class="flex flex-wrap items-stretch w-full mt-2"
        >
          <div class="flex-1">
            <div class="flex flex-wrap items-stretch rounded border w-full">
              <input
                v-if="iHavePayDunyaAccount"
                v-model="customerEmail"
                type="text"
                class="flex-shrink flex-grow flex-auto  leading-normal w-px h-10 px-4"
                placeholder="rema2016@gmail.com"
              >
            </div>
          </div>
        </div>
        <label
          v-if="!isValidation"
          class="block mt-2 tracking-wide text-gray-700 text-ml mb-2"
        >
          {{ $t('visaAmount') }} <b> {{ valueInEuro }} €</b>
        </label>
        <div class="flex justify-end  mt-5">
          <div
            class="flex-one flex justify-between w-1/2 p-3 pt-4 cursor-pointer font-bold rounded border-none action-button-real bg-green text-white text-center"
            @click="goNextChoose"
          >
            <h3
              v-if="!loadingCard"
              class="flex-two w-full uppercase"
            >
              {{ $t('next') }}
            </h3>
            <h3
              v-if="!loadingCard"
              class="flex-two w-1/5 text-center"
            >
              <svg-icon
                name="ic-next"
                class="w-3 h3"
                original
              />
            </h3>
            <h3
              v-if="loadingCard"
              class="flex-two w-full uppercase"
            >
              <pulse-loader color="#fff" />
            </h3>
          </div>
        </div>
      </div>
      <!--Choisir Code de recharge REMA-->
      <div
        v-else-if="!showChoix"
        class="shadow rounded p-6  funding-dialog"
      >
        <h3 class="text-2xl font-bold pb-4">
          {{ $t('fundingWallet') }}
        </h3>
        <div class="flex p-4 border-t border-b font-light">
          <h3
            class="flex-one w-1/8 font-light cursor-pointer"
            @click="choosedRema"
          >
            <svg-icon
              v-if="choosed === 'rema'"
              name="ic-radio-choosed"
              class="h-4"
              original
            />
            <svg-icon
              v-if="choosed === 'visa'"
              name="ic-radio"
              class="h-4"
              original
            />
          </h3>
          <h3 class="flex-one w-full text-sm font-light font-bold ml-2">
            {{ $t('remaCode') }}
          </h3>
          <h3 class="flex-one w-1/4 text-right font-light">
            <svg-icon
              name="ic-more-rema"
              class=" h-4"
              original
            />
          </h3>
        </div>
        <div class="flex flex-wrap items-stretch w-full mb-4 mt-2 py-4">
          <div class="flex-1">
            <label class="uppercase block tracking-wide text-gray-700 text-xs mb-2">
              {{ $t('enterCode') }}
            </label>
            <div
              class="flex flex-wrap items-stretch rounded border w-full"
              :class="{'isError': showErrorSearch,
                       'isSuccess': showSuccessSearch}"
            >
              <div class="flex -mr-px">
                <span class="flex items-center leading-normal px-2 whitespace-no-wrap text-grey-dark text-sm">
                  <svg-icon
                    name="ic-more-white"
                    class="w-4 h-10"
                    :color="beforeColor"
                    original
                  />
                </span>
              </div>
              <input
                v-model="codeNumber"
                type="text"
                class="flex-shrink flex-grow flex-auto leading-normal w-px px-0"
                placeholder="5DJJ8152STTHSDDS"
                maxlength="8"
                @focus="changeColorInput"
                @blur="resetColorInput"
                @input="checkNodeExit"
              >
              <div class="flex -mr-px">
                <span class="flex items-center leading-normal px-2  whitespace-no-wrap text-grey-dark text-sm">
                  <clip-loader
                    v-if="showSearch"
                    class="mt-1"
                    size="17px"
                  />
                  <svg-icon
                    v-if="showSuccessSearch"
                    class="w-6 h-12"
                    name="ic-sucess"
                    original
                  />
                  <svg-icon
                    v-if="showErrorSearch"
                    class="w-6 h-12"
                    name="ic-fail"
                    original
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <h3
          v-if="showAmount"
          class="text-sm uppercase font-normal font-bold"
        >
          Montant: <span class="is-find">{{ codeAmount }} €</span>
        </h3>
        <div class="flex justify-end mt-5">
          <div
            :class="{'ready': showAmount}"
            class="flex-one flex justify-between w-1/2 p-3 pt-4 cursor-pointer font-bold rounded border-none action-button-real bg-grey text-white text-center"
            @click="fundingWithRemaCode"
          >
            <h3
              v-if="!loading"
              class="flex-two w-full uppercase"
            >
              {{ $t('next') }}
            </h3>
            <h3
              v-if="loading"
              class="flex-two w-full uppercase"
            >
              <pulse-loader color="#fff" />
            </h3>
            <h3
              v-if="!loading"
              class="flex-two w-1/5 text-center"
            >
              <svg-icon
                name="ic-next"
                class="w-3 h3"
                original
              />
            </h3>
          </div>
        </div>
      </div>
      <!-- Success funding wallet -->
    </div>
  </div>
</template>

<script>

import { firebaseDb } from '../../../main'
import { firebaseRef } from '../../../rema-function/firebaseRef'
import ClipLoader from 'vue-spinner/src/ClipLoader'
import firebase from 'firebase'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import { remaDefaultData } from '../../../rema-function/remaDefaultData'
import ClickOutside from 'vue-click-outside'

let PayDunya = require('paydunya')
let setup = new PayDunya.Setup({
  masterKey: 'mlbbrcjJ-RgO0-RSR3-1sXa-xCH1RKKutNkf',
  privateKey: 'live_private_thUsHtOyltSfQ0C3n4undcuAgXE',
  publicKey: 'live_public_FVZ6UDwkIEI5nuF5ERsUl4r8bTA',
  token: 'Qwj77zkpY9uz3LCOV1sC'
  // mode: 'live' // optional. use in sandbox mode.
})
let store = new PayDunya.Store({
  name: 'REMA Medical Technologies'
  // cancelURL: 'http://192.168.0.128:8080/'
  // cancelURL: url
})
let newInvoice = new PayDunya.OnsiteInvoice(setup, store)

export default {
  name: 'FundindWallet',
  components: { PulseLoader, ClipLoader },
  directives: {
    ClickOutside
  },
  data () {
    return {
      choosed: 'visa',
      showChoice: true,
      showChoix: true,
      codeNumber: '',
      fundingSucess: true,
      cardNumber: '',
      cardExpiration: '',
      cardCvv: '',
      cardName: '',
      cardAmount: '',
      showSearch: false,
      showSuccessSearch: false,
      showErrorSearch: false,
      walletCodesList: [],
      codeAmount: 0,
      loading: false,
      showAmount: false,
      findCode: {},
      beforeColor: '#BEBEBE',
      latestCode: 0,
      waitingKey: '',
      loadingCard: false,
      payDunyyaValidationCode: '',
      isValidation: false,
      iHavePayDunyaAccount: false,
      customerEmail: '',
      alreadyClick: false
    }
  },
  computed: {
    valueInEuro: function () {
      if (this.cardAmount !== '') {
        return (Number.parseInt(this.cardAmount) / remaDefaultData.baseEuro).toFixed(2)
      } else {
        return 0
      }
    }
  },
  created () {
    let vm = this
    firebaseDb.ref(firebaseRef.wallets).child('lastWalletFundingId').once('value', function (snapshot) {
      vm.latestCode = snapshot.val()
    })
  },
  methods: {
    haveAccount () {
      this.iHavePayDunyaAccount = !this.iHavePayDunyaAccount
    },
    emitClose () {
      this.$emit('closeFunding', false)
    },
    choosedBank () {
      this.choosed = 'visa'
    },
    choosedRema () {
      this.choosed = 'rema'
    },
    hidePopup () {
      alert('meeeeeeeee')
    },
    goNextChoose () {
      if (this.choosed === 'visa') {
        if (this.showChoice === false) {
          if (this.valueInEuro >= 0) {
            if (!this.isValidation) {
              this.generateWatingData()
            } else {
              this.confirmPaymentId()
            }
          } else {
            alert('Le montant doit être suppérieure à 0')
          }
        } else {
          this.showChoice = false
        }
      } else {
        this.showChoix = false
      }
      // alert(this.choosed)
    },
    checkNodeExit () {
      if (this.codeNumber.length === 8) {
        this.showSearch = true
        let vm = this
        firebaseDb.ref(firebaseRef.walletCode).child(this.codeNumber)
          .once('value', snapshot => {
            if (snapshot.exists()) {
              this.findCode = snapshot.val()
              setTimeout(function () {
                vm.updateLoad()
                vm.updateStateFind()
              }, 3000)
            } else {
              setTimeout(function () {
                vm.updateLoad()
                vm.updateStateError()
              }, 3000)
            }
          })
      } else {
        this.showSearch = false
        this.showSuccessSearch = false
        this.showErrorSearch = false
        this.showAmount = false
      }
    },
    updateStateFind () {
      if (this.findCode.isValid) {
        this.showSearch = false
        this.showErrorSearch = false
        this.showSuccessSearch = true
        this.codeAmount = this.findCode.amount
        this.showAmount = true
      } else {
        this.updateStateError()
      }
    },
    updateStateError () {
      this.showSearch = false
      this.showErrorSearch = true
      this.showSuccessSearch = false
      this.showAmount = false
    },
    isNumberOnly ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    },
    updateLoad () {
      this.showSearch = true
    },
    changeColorInput () {
      this.beforeColor = '#000'
    },
    resetColorInput () {
      this.beforeColor = '#BEBEBE'
    },
    fundingWithRemaCode () {
      if (this.codeNumber.length < 8) {
        alert('le code doit faire 8 charactère')
      } else {
        if (this.showSuccessSearch && !this.alreadyClick) {
          this.alreadyClick = true
          let vm = this
          this.loading = true
          let re = this.latestCode + 1
          let recharge = {
            type: 'rema',
            date: new Date().getTime(),
            amount: this.findCode.amount,
            ref: re
          }
          let ref = firebaseDb.ref(firebaseRef.wallets + firebase.auth().currentUser.uid)
          firebaseDb.ref(firebaseRef.wallets + firebase.auth().currentUser.uid).once('value', snapshot => {
            if (snapshot.exists()) {
              let current = snapshot.val()
              current.balance = parseFloat(current.balance) + parseFloat(vm.findCode.amount)
              current.history.push(recharge)
              if (ref.update(current)) {
                // vm.findCode.isValid = false
                firebaseDb.ref(firebaseRef.wallets).child('lastWalletFundingId').set(re)
                if (firebaseDb.ref(firebaseRef.walletCode).child(vm.findCode.uid).child('isValid').set(false)) {
                  vm.loading = false
                  vm.emitClose()
                  vm.$emit('sucsessFunding', true)
                }
              } else {
                vm.cb(false)
                alert('no there')
              }
            } else {
              var first = {
                balance: this.findCode.amount,
                history: []
              }
              first.history.push(recharge)
              if (ref.set(first)) {
                if (firebaseDb.ref(firebaseRef.walletCode).child(vm.findCode.uid).update(vm.findCode)) {
                  vm.loading = false
                  vm.$emit('sucsessFunding', true)
                }
              } else {
                vm.cb(false)
              }
            }
          })
        } else {
          if (!this.alreadyClick) {
            alert('code invalid')
          }
        }
      }
    },
    payWithDunya () {
      // alert('his')
      let vm = this
      let url = remaDefaultData.baseUrlPaydunya + firebase.auth().currentUser.uid + '/' + this.waitingKey + '/1'
      // alert(url)
      let payDunya = require('paydunya')
      let setup = new payDunya.Setup({
        masterKey: 'mlbbrcjJ-RgO0-RSR3-1sXa-xCH1RKKutNkf',
        privateKey: 'live_private_thUsHtOyltSfQ0C3n4undcuAgXE',
        publicKey: 'live_public_FVZ6UDwkIEI5nuF5ERsUl4r8bTA',
        token: 'Qwj77zkpY9uz3LCOV1sC'
        // mode: 'live' // optional. use in sandbox mode.
      })
      let store = new payDunya.Store({
        name: 'REMA Medical Technologies',
        // cancelURL: 'http://192.168.0.128:8080/'
        cancelURL: url
      })
      let invoice = new payDunya.CheckoutInvoice(setup, store)
      invoice.totalAmount = Number.parseFloat(this.cardAmount)
      invoice.addChannel('card')
      invoice.returnURL = url
      invoice.create().then((res) => {
        console.log(invoice.status)
        console.log(invoice.token) // Token de facture
        console.log(invoice.responseText)
        console.log(invoice.url)
        let data = {
          amount: Number.parseFloat(vm.valueInEuro),
          realAmount: Number.parseFloat(vm.cardAmount),
          type: 'visa',
          token: invoice.token
        }
        firebaseDb.ref(firebaseRef.wallets + firebase.auth().currentUser.uid + '/' + firebaseRef.walletsWaiting)
          .child(vm.waitingKey)
          .set(data).then(() => {
            vm.loadingCard = false
            window.open(invoice.url, '_self')
          }).catch((err) => {
            alert(err.toString())
          })
      })
        .catch((err) => {
          alert(err.toString())
          vm.loadingCard = false
          console.log(err)
        })
    },
    payOnSitePayDunya () {
      let vm = this
      newInvoice.totalAmount = Number.parseFloat(this.cardAmount)
      // invoice.addChannel('card')
      // invoice.returnURL = url
      newInvoice.create(vm.customerEmail).then((res) => {
        console.log(newInvoice.status)
        console.log(newInvoice.token) // Token de facture
        console.log(newInvoice.responseText)
        console.log(newInvoice.url)
        vm.loadingCard = false
        vm.isValidation = true
      })
        .catch((err) => {
          alert('Vous ne pouvez pas utilisé cette methode sans compte PayDunya')
          vm.loadingCard = false
          console.log(err)
        })
    },
    confirmPaymentId () {
      if (this.payDunyyaValidationCode.length < 8) {
        alert('le code doit faire 08 chiffres')
      } else {
        let url = remaDefaultData.baseUrlPaydunya + firebase.auth().currentUser.uid + '/' + this.waitingKey + '/0'
        console.log(url)
        let vm = this
        this.loadingCard = true
        console.log(newInvoice.oprToken)
        console.log(this.payDunyyaValidationCode)
        newInvoice.charge(newInvoice.oprToken.toString(), this.payDunyyaValidationCode.toString())
          .then(function () {
            console.log(newInvoice.status +
            newInvoice.responseText +
            newInvoice.receiptURL)
            window.open(url, '_self')
          })
          .catch(function (e) {
            console.log(e)
            console.log(e.data.response_code)
            alert('Montant insuffisant')
            vm.loadingCard = false
            vm.isValidation = false
            vm.iHavePayDunyaAccount = false
            // window.location.reload()
            // window.open(url, '_self')
          })
      }
    },
    generateWatingData () {
      let vm = this
      vm.loadingCard = true
      let ref = firebaseDb.ref(firebaseRef.wallets + firebase.auth().currentUser.uid + '/' + firebaseRef.walletsWaiting).push()
      vm.waitingKey = ref.key
      let data = {
        amount: Number.parseFloat(vm.valueInEuro),
        realAmount: Number.parseFloat(vm.cardAmount),
        type: 'visa'
      }
      ref.set(data)
        .then(() => {
          if (!vm.iHavePayDunyaAccount) {
            vm.payWithDunya()
          } else {
            vm.payOnSitePayDunya()
          }
        })
        .catch((err) => {
          vm.loadingCard = false
          alert(err.toString())
        })
    }
  },
  firebase () {
    return {
      walletCodesList: firebaseDb.ref(firebaseRef.walletCode)
      // latestCode: firebaseDb.ref(firebaseRef.wallets).child('lastWalletFundingId')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/mixin";
  .funding-dialog {
    background-color: white;
    margin: auto;
    width: 100%;
    // background-color: red;
  }
  .funding-wallet-red {
    color: $red;
    background-color: $Rema-Content-Background;
  }
  .divider {
    height: .04rem;
    background-color: $Divider-Rema-grey;
  }
 .icon-info {
   padding-top: .1rem;
 }
  .contact-us a {
    color: $Green-Rema;
  }
  .loader-icon {
    height: .3rem;
  }
  .is-find {
    color: $Green-Rema;
  }
  .ready {
   background-color: $Green-Rema;
  }
  .isError {
    border-color: $red;
  }
  .isSuccess {
    border-color: $Green-Rema;
  }
  .success-funding-wallet {
    width: 40%;
    margin: auto;
  }
.popper {
  box-shadow: none;
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  color: black;
  font-size: .7rem;
}
.checkbox-container {
  height: 18px;
  width: 18px;
  border: solid 1px gray;
  cursor: pointer;
  border-radius: 2.5px;
  padding: .9%;
}
.checkbox-color {
  height: 10px;
  width: 10px;
  background: #1f9d55;
  border-radius: 2.5px;
}
.funding-wallet {
  display: flex;
  justify-content: center;
  position: fixed;
  height: 100vh;
  min-height: 100vh;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.39);
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.3);
  z-index: 50;
  overflow-y: scroll;
  text-align: left;
}
.funding-wallet-sub {
  position: relative;
  display: flex;
  color: black;
  flex-direction: column;
  // height: 100%;
  margin-bottom: 50px;
 // min-height: 1000px;
 //  background-color: red;
  width: 30%;
  // box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}
</style>
